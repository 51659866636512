import React from 'react';
import '../../scss/Portfolio.scss';
import booklist from '../../images/booklist.png'
import flashcards from '../../images/flashcards.png'
import flashcards2 from '../../images/flashcards2.png'
import imagesearch from '../../images/imagesearch.png'


const Portfolio = () => {
  return (
    <section id="portfolio" className="portfolio">
      <h2 className="portfolio__title">Projects</h2>
      <div className="portfolio__items">

        <div className="portfolio__item">
          <img src={booklist} alt="Project" className="portfolio__item-image" />
          <h3 className="portfolio__item-title">Eficode Library</h3>
          <p className="portfolio__item-description">Collaborative project for Eficode Oy</p>
          <p className="portgolio__item-stack">
            React, CSS, TypeScript, Robot Framework
          </p>
          <a href="https://github.com/LibraryProjectGroup/library-project-frontend" className="portfolio__item-link">View Project</a>
        </div>

        <div className="portfolio__item">
          <div className="portfolio__item_image-multiple">
          <img src={flashcards} alt="Project" className="portfolio__item-image" />
          <img src={flashcards2} alt="Project" className="portfolio__item-image" />
          </div>
          <h3 className="portfolio__item-title">Flashcards</h3>
          <p className="portfolio__item-description">Project for a mobile development course</p>
          <p className="portgolio__item-stack">
            React Native, CSS, Async Storage
          </p>
          <a href="https://github.com/rikvart/react-native-flashcards-async" className="portfolio__item-link">View Project</a>
        </div>

        <div className="portfolio__item">
          <img src={imagesearch} alt="Project" className="portfolio__item-image" />
          <h3 className="portfolio__item-title">Unsplashed Image Search</h3>
          <p className="portfolio__item-description">Simple image search made with Unsplashed API and axios</p>
          <p className="portgolio__item-stack">
            React, CSS
          </p>
          <a href="https://github.com/rikvart/PictureSearch" className="portfolio__item-link">View Project</a>
        </div>




      </div>
    </section>
  );
};

export default Portfolio;
