import React from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import '../scss/Footer.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__content">
        <span>© 2023 Riku Vartiainen</span>
        <a href="https://github.com/rikvart" target="_blank" rel="noopener noreferrer" className="footer__link">
          <FaGithub className="footer__icon" />
        </a>
        <a href="https://linkedin.com/in/riku-vartiainen" target="_blank" rel="noopener noreferrer" className="footer__link">
          <FaLinkedin className="footer__icon" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;