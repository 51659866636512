import React from 'react';
import { Link } from 'react-router-dom';
import '../scss/Navigation.scss';

const Navigation = () => {
  return (
    <nav className="navigation">
      <ul className="navigation__list">
        <li className="navigation__item">
          <Link to="/" className="navigation__link">
            Home
          </Link>
        </li>
        <li className="navigation__item">
          <Link to="/portfolio" className="navigation__link">
            Portfolio
          </Link>
        </li>
        <li className="navigation__item">
          <Link to="/contact" className="navigation__link">
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
