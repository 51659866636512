import React from "react";
import emailjs from "emailjs-com";
import "../../scss/Contact.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const handleSuccess = () => toast("Email sent!");
  const handleError = () => toast("Error sending email!");

  const sendEmail = (e) => {
    e.preventDefault();

    // Replace with your own EmailJS template and user ID
    const templateId = "template_rbysf25";
    const userId = "jl1R3mgC3mO_g_WzB";

    emailjs
      .sendForm("service_gk5dcdm", templateId, e.target, userId)
      .then((result) => {
        console.log("Email sent successfully!");
        handleSuccess();
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        handleError();
      });
  };

  return (
    <div className="contact">
      <h1>Contact me</h1>
      
      <form onSubmit={sendEmail}>
        <label htmlFor="name">Name</label>
        <input type="text" id="name" name="name" required />
        <label htmlFor="email">Email</label>
        <input type="email" id="email" name="email" required />
        <label htmlFor="message">Message</label>
        <textarea className="message" id="message" name="message" required></textarea>
        <button type="submit">Send Email</button>
      </form>
      <ToastContainer
        position="bottom-left"
        autoClose={2500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default Contact;
