import React from 'react';
import { FaReact, FaNodeJs, FaJs, FaHtml5, FaCss3Alt, FaSass, FaDatabase, FaGit, FaJava } from 'react-icons/fa';

import '../../scss/Home.scss'

const Home = () => {
  return (
    <div className="home">
      <section className="home__content">
        <h1 className="home__title">Riku Vartiainen</h1>
        <p className="home__subtitle">Web Developer</p>
      </section>
      <div><a href="/portfolio" className="home__cta">View My Work</a></div>
      <section>
        
        <div className="skills">
          <div className="skill">
            <FaReact className="skill-icon" />
            <span>React</span>
          </div>
          <div className="skill">
            <FaNodeJs className="skill-icon" />
            <span>Node.js</span>
          </div>
          <div className="skill">
            <FaJs className="skill-icon" />
            <span>JavaScript</span>
          </div>
          <div className="skill">
            <FaHtml5 className="skill-icon" />
            <span>HTML</span>
          </div>
          <div className="skill">
            <FaCss3Alt className="skill-icon" />
            <span>CSS</span>
          </div>
          <div className="skill">
            <FaSass className="skill-icon" />
            <span>SCSS</span>
          </div>
          <div className="skill">
            <FaDatabase className="skill-icon" />
            <span>SQL</span>
          </div>
          <div className="skill">
            <FaJava className="skill-icon" />
            <span>Java</span>
          </div>
          <div className="skill">
            <FaGit className="skill-icon" />
            <span>Git</span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
